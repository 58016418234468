<template>
  <div class="myOrder">
    <div class="container">
      <Menu :active="2"/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <span>Check,</span> My Orders
        </div>
        <div class="order bWrap">
          <div class="row">
            <div class="th">Product</div>
            <div class="th">Order Date</div>
            <div class="th">Order Status</div>
          </div>
          <div v-if="orders.length">
            <div
                v-for="(item,index) in orders"
                :key="index"
                class="tr"
                @click="router.push('/myOrder/detail?id=' + item.order_id)"
            >
              <div class="flex">
                <div class="td">
                  <img class="pro" :src="item.images">
                  <div class="text">{{ item.name }}</div>
                </div>
                <div class="td">{{ item.add_time }}</div>
                <div class="td">
                  <div v-if="item.order_status == 1">Shipping</div>
                  <div v-if="item.order_status == 2">Successfully Redeemed</div>
                  <div v-if="item.order_status == 3">Failed</div>
                  <div class="icon">
                    <img src="@/assets/imgs/icon_arrowR.png">
                    <img src="@/assets/imgs/icon_arrowR_active.png">
                  </div>
                </div>
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div v-else class="noData">No data available</div>
          <div class="page">
            <a-pagination
                v-model:current="page"
                :total="count"
                :pageSize="pageSize"
                hideOnSinglePage
                show-less-items
                @change="handleChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { orderList } from '@/request/api'

  const router = useRouter()
  const page = ref(1)
  const orders = ref([])
  const count = ref(1)
  const pageSize = ref(10)

  let address = ''
  function getContract(obj) {
    address = obj.address
    getList()
  }

  function getList() {
    orderList({
      address,
      page: page.value,
      limit: pageSize.value
    }).then(res => {
      if(res.success) {
        orders.value = res.data.list
        count.value = res.data.count
      }
    })
  }

  function handleChange(p) {
    page.value = p
    getList()
  }
</script>

<style lang="less" scoped>
.myOrder{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main {
      width: 888px;
      padding-top: 20px;

      .welcome{
        font-size: 33px;
        margin: 54px 0 27px 0;

        span{
          color: #697377;
        }
      }
      .order{
        min-height: calc(100vh - 210px);

        .row{
          border-bottom: 2px solid #747474;
          height: 60px;
          display: flex;
          align-items: center;
          padding-left: 20px;

          .th{
            font-size: 17px;
            flex: 1;
          }
          .th:last-child{
            flex: 1.4;
          }
        }
        .tr{
          padding-left: 20px;

          .flex{
            display: flex;
            align-items: center;
            padding: 22px 0;
            cursor: pointer;

            .td{
              display: flex;
              align-items: center;
              font-size: 17px;
              flex: 1;
              position: relative;

              .pro{
                width: 35px;
                height: 35px;
                margin-right: 24px;
                border-radius: 10px;
              }
              .small{
                font-size: 12px;
                color: #697377;
                margin-top: 6px;
              }
              .icon{
                width: 17px;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);

                img:last-child{
                  display: none;
                }
              }
            }
            .column{
              flex-direction: column;
              align-items: flex-start;
            }
            .td:last-child{
              flex: 1.4;
            }
          }
          .line{
            height: 0.7px;
            border-radius: 12px;
            background: radial-gradient(50% 50% at 50% 50.00%, #949494 78.65%, #000 100%);
          }
        }
        .tr:hover{
          color: #06C8A1;

          .flex .td .icon{
            img:first-child{
              display: none;
            }
            img:last-child{
              display: block;
            }
          }
        }
        .noData{
          text-align: center;
          padding-top: 40px;
        }
        .page{
          padding: 30px 0 20px 0;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
